import { createContext, useCallback, useEffect, useState } from 'react'
import { onIdTokenChanged, signOut, User as FirebaseUser } from 'firebase/auth'
import { useQuery } from 'react-query'
import { Organization, Permission, User } from '@signupsoftware/server'
import { apiClient } from '../config/axios.config'
import { auth } from '../config/firebase.config'
import { queryClient } from '../config/react-query.config'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useWindowSize } from '@unimpaired/react'
import { GLOBAL_BREADCRUMB_HEIGHT } from './layout/MobileBreadcrumbs'

const defaultContext = {
  breadcrumbOverrides: {
    '/': '/',
  },
  setBreadcrumbOverrides: () => {},
  viewportHeight: 0,
}

type DefaultContext = {
  breadcrumbOverrides: Record<string, string>
  setBreadcrumbOverrides: SetState<Record<string, string>>
  viewportHeight: number
}

export const UiContext = createContext<DefaultContext>(defaultContext)

export const UiProvider = ({ children }: any) => {
  const [windowHeight] = useWindowSize()
  const [breadcrumbOverrides, setBreadcrumbOverrides] = useState<
    DefaultContext['breadcrumbOverrides']
  >({})

  return (
    <UiContext.Provider
      value={{
        breadcrumbOverrides,
        setBreadcrumbOverrides,
        viewportHeight: windowHeight - GLOBAL_BREADCRUMB_HEIGHT,
      }}>
      {children}
    </UiContext.Provider>
  )
}
