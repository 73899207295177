import { Organization, User } from '@signupsoftware/server'
import { useQuery } from 'react-query'
import { useOutletContext } from 'react-router'
import { apiClient } from '../../config/axios.config'

export const useAdminContext = () => {
  const { user, permissions, organization } = useOutletContext() as {
    user: User
    permissions: Permissions
    organization: Organization
  }

  const { data: privileges, ...query } = useQuery<string[]>({
    queryKey: ['get-allowed-permissions', user?.id, organization?.id],
    queryFn: async () =>
      apiClient
        .post<string[]>('get-allowed-permissions', {
          userId: user?.id,
          organizationId: organization?.id,
        })
        .then((res) => res.data),
    enabled: !!user?.id && !!organization?.id,
  })

  const hasPermission = (actions: string[]) => {
    if (query.isLoading) return false
    if (!privileges) return false
    const allowedSet = new Set(privileges)
    return actions.some((item) => allowedSet.has(item))
  }

  return { user, permissions, organization, hasPermission }
}
