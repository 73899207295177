import { Button, Page, RoutingNav, Section, useRouter } from '@unimpaired/react'
import { PageProps } from '../../../../libraries/react/types/components/Layout/Page'
import {
  faArrowLeft,
  faCalendar,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  title: string
  links: any[]
} & Omit<PageProps, 'children'>
export const NavigationPage = ({ links, ...props }: Props) => {
  const { navigateTo } = useRouter()
  return (
    <Page
      {...props}
      condensed
      className='mt-0'
      titleActionsComponent={
        <Button
          type='button'
          variant='outline-dark'
          iconPosition='left'
          className='min-w-fit'
          icon={faArrowLeft}
          onClick={() => navigateTo('..')}>
          <p>Back</p>
        </Button>
      }>
      <RoutingNav variant='dark' navItems={links} />
    </Page>
  )
}

export const orgDefaultsLinks = [
  {
    route: 'class',
    text: 'Class Settings',
    comingSoon: true,
    description:
      'These settings will be preselected when creating and editing a class',
  },
  {
    route: 'dance',
    comingSoon: true,
    text: 'Dance Settings',
    description:
      'These settings will be preselected when creating and editing a dance',
  },
  {
    route: 'payment-success-email',
    text: 'Payment Success Email',
    comingSoon: true,
    description:
      'These settings will be preselected when creating and editing a class',
  },
]

export const startScanningLinks = [
  {
    route: '?=classes',
    text: 'Scan for a group class',
    icon: faPeopleGroup,
    // description:
    //   '',
  },
  {
    route: '?=dances',
    text: 'Scan for a dance',
    icon: faCalendar,
    // description:
    //   'These settings will be preselected when creating and editing a dance',
  },
]

export const storeLinks = [
  {
    route: `classes`,
    text: 'Group Classes',
    description: 'Check out our group classes. Learn to dance with us!',
  },
  {
    route: `dances`,
    text: 'Social Dances',
    description: 'Check out social dances. Dance with us!',
  },
]

export const profileLinks = [
  {
    route: 'settings',
    text: 'Settings',
    description: 'Add venues, teachers, waivers, and more',
  },
  {
    route: 'defaults',
    text: 'Defaults',
    comingSoon: true,
    description: 'default prices',
  },
  {
    route: 'branded-apps',
    text: 'Get Custom Website / App',
    comingSoon: true,
    description: 'Move your registration system into your own custom platform',
  },
  {
    route: 'venues',
    text: 'Venues',
    comingSoon: true,
    description: 'Manage your venues',
  },
  {
    route: 'files',
    text: 'Files & Media',
    comingSoon: true,
    description: 'Manage saved videos, images, and documents',
  },
  {
    route: 'purchase-history',
    text: 'Purchase History',
    comingSoon: true,
    description:
      'The costs you have paid in order to host events on this platform.',
  },
]

export const offeringLinks = [
  {
    route: `classes`,
    text: 'Group Classes',
    description:
      'Create/edit classes. Manage students. Scan tickets. View analytics',
  },
  {
    route: `dances`,
    text: 'Social Dances',
    description:
      'Create/edit classes. Manage attendees. Scan tickets. View analytics',
  },
  {
    route: `workshops`,
    text: 'Weekend Workshops',
    comingSoon: true,
    description:
      'Plan and manage weekend workshops. Manage attendees. View analytics',
  },
  {
    route: `private-lessons`,
    text: 'Private Lessons',
    comingSoon: true,
    description:
      'Plan and manage weekend workshops. Manage attendees. View analytics',
  },
  {
    route: `Virtual Promotions`,
    text: 'Memberships, Bundles, & Promotions',
    comingSoon: true,
    description: 'Punch cards, memberships, discounts, etc.',
  },
  {
    route: `physical-goods`,
    text: 'Goods to Sell',
    comingSoon: true,
    description: 't-shirts, buttons, stickets, etc.',
  },

  // {
  //   route: `/admin/${organizationId}/users`,
  //   text: 'Users',
  //   description: 'Students / Teachers / Staff',
  // },
  // {
  //   route: `/admin/${organizationId}/organization-settings/media`,
  //   text: 'Media',
  //   description: 'Photos / Videos / Recaps',
  // },
  // {
  //   route: '/admin/dance-moves',
  //   text: 'Dance Moves',
  //   description: '6 second clips describing a dance move',
  // },
  // {
  //   route: '/admin/marketing',
  //   text: 'Communication',
  //   description: 'Emails / Texts / Notifications',
  // },
  // {
  //   route: '/admin/marketing',
  //   text: 'Marketing',
  //   description: 'Social Media',
  // },
  // {
  //   route: '/finances',
  //   text: 'Finances',
  //   description: 'Cost / Profit / Revenue',
  // },
  // {
  //   route: '/notifications',
  //   text: 'Notifications',
  //   description: 'Send Emails / Texts'
  // },

  // {
  //   route: '/history',
  //   text: 'History',
  //   description: 'API Request History',
  // },
]
