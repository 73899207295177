import {
  createBrowserRouter
} from 'react-router-dom'
import GroupClassDashboardPage from './pages/GroupClassDashboardPage'
import {
  NavigationPage,
  offeringLinks,
  orgDefaultsLinks,
  profileLinks,
  startScanningLinks,
} from './pages/NavigationPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { OrgNavPage, adminLinks, storeLinks } from './pages/OrgNavPage'

export const router = createBrowserRouter([
  // Ticket is denormalized and has a HASH index of ticketId.  Only needs react-router and the U.I.
  // We separate it from everything else in the app
  { path: '/ticket/:ticketId', lazy: () => import('./pages/TicketPage') },
  { path: '/sendemails', lazy: () => import('./pages/SendEmailsPage') },
  {
    path: '/',
    lazy: () => import('./pages/AppPage'),
    children: [
      { path: '', lazy: () => import('./pages/HomePage') },
      { path: 'my-tickets', lazy: () => import('./pages/MyTicketsPage') },
      { path: 'getting-started', lazy: () => import('./pages/GettingStartedPage') },
      {
        path: 'admin',
        children: [
          { path: '', lazy: () => import('./pages/AdminChooseOrganzation') },
          { path: 'create-organization', lazy: () => import('./pages/admin.create-new-organization') },
          { path: 'su', children: [
            { path: '', lazy: () => import('./pages/admin.su') },
          ]},
          {
            path: ':oid',
            lazy: () => import('./pages/OrganizationDashboardPage'),
            children: [
              { path: '', element: <OrgNavPage admin links={adminLinks}  /> },
              { path: 'overview', lazy: () => import('./pages/admin.$oid.overview') },
              {
                path: 'scan',
                lazy: () => import('./pages/ScanEventTickets'),
                children: [
                  { path: '', element: ( <NavigationPage title='Scan Tickets' links={startScanningLinks} />) },
                  { path: 'classes', element: ( <NavigationPage title='Organization Defaults' links={orgDefaultsLinks} />) },
                  { path: 'dances', lazy: () => import('./pages/OrganizationEditPage') },
                ],
              },
              {
                path: 'profile',
                children: [
                  { path: '', element: ( <NavigationPage title='Business Profile' links={profileLinks} />) },
                  { path: 'defaults', element: ( <NavigationPage title='Organization Defaults' links={orgDefaultsLinks} />) },
                  { path: 'settings', lazy: () => import('./pages/OrganizationEditPage') },
                ],
              },
              {
                path: 'offerings',
                children: [
                  { path: '', element: ( <NavigationPage title='Classes, Events, & Offerings' pageDescription='Manage your offerings' links={offeringLinks} />) },
                  {
                    path: 'dances',
                    children: [
                      { path: '', lazy: () => import('./pages/SocialDanceDashboardPage') },
                      { path: 'create', lazy: () => import('./pages/SocialDanceCreatePage') },
                      {
                        path: ':productId',
                        lazy: () => import('./pages/EditEventProductLayout'),
                        children: [
                          { path: '', lazy: () => import('./pages/SocialDanceSettingsPage') },
                          { path: 'scan', lazy: () => import('./pages/SocialDanceScanPage') },
                          { path: 'attendees', lazy: () => import('./pages/SocialDanceAttendeesPage') },
                          { path: 'analytics', lazy: () => import('./pages/SocialDanceAnalyticsPage') },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'classes',
                    children: [
                      { path: '', element: <GroupClassDashboardPage /> },
                      { path: 'create', lazy: () => import('./pages/GroupClassCreatePage') },
                      {
                        path: ':productId',
                        lazy: () => import('./pages/EditEventProductLayout'),
                        children: [
                          { path: '', lazy: () => import('./pages/GroupClassSettingsPage') },
                          { path: 'scan', lazy: () => import('./pages/GroupClassScanPage') },
                          { path: 'attendees', lazy: () => import('./pages/GroupClassAttendeesPage') },
                          { path: 'analytics', lazy: () => import('./pages/GroupClassAnalyticsPage') },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'store',
        children: [
          { path: '', lazy: () => import('./pages/OrganizationSelectPage') },
          {
            path: ':oid',
            lazy: () => import('./pages/OrganizationStoreLayoutPage'),
            children: [
              { path: '', element: <OrgNavPage links={storeLinks} /> },
              {
                path: 'classes',
                children: [
                  { path: '', lazy: () => import('./pages/GroupClassesStorefrontPage') },
                  { path: ':classId', lazy: () => import('./pages/PurchaseGroupClassPage') },
                ],
              },
              {
                path: 'dances',
                children: [
                  { path: '', lazy: () => import('./pages/SocialDanceStorefrontPage') },
                  { path: ':danceId', lazy: () => import('./pages/PurchaseSocialDancePage') },
                ],
              },
            ],
          },
        ],
      },
    ]
  },
  { path: '*', element: <NotFoundPage goBackPath='/' goBackText='Go back home' /> },
]) as any
