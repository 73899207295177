import { formatDate } from '@unimpaired/utils'
import {
  addMinutes,
  addMonths,
  addWeeks,
  compareAsc,
  differenceInMinutes,
  endOfYear,
  isBefore,
  isFuture,
  isPast,
  isSameMonth,
  isSameWeek,
  isThisMonth,
  isThisWeek,
  isToday,
  isTomorrow,
} from 'date-fns'
import { RRule } from 'rrule'

export type EventProduct = {
  type: string
  startTime: Date
  endTime: Date
  recurrence?: null | string
  priority?: number
} & Record<string, any>

/**
  Return only dates that are before the end of this year
  This prevents the page from crashing when the recurrence is indefinite(rrule tries to create an array with an infinite amount of dates)
*/
export const getRRuleDates = (rrule: string | RRule) => {
  const rule = typeof rrule === 'string' ? RRule.fromString(rrule) : rrule
  return rule
    .all((date) => isBefore(date, endOfYear(new Date())))
    .sort(compareAsc)
}

export const getNextEventDate = (eventProduct: EventProduct) => {
  const { startTime, recurrence } = eventProduct
  if (!recurrence) return startTime
  return getRRuleDates(recurrence).find((date) => isFuture(date)) || startTime
}

export const getEventDateDescription = (date: Date) => {
  if (isToday(date)) return 'Starts Today'
  if (isTomorrow(date)) return 'Starts Tomorrow'
  if (isSameWeek(date, addWeeks(new Date(), 1))) return 'Starts Next Week'
  if (isPast(date)) return 'Already Happened'
  if (isThisWeek(date)) return 'Starts This Week'
  // if (isThisWeek(date) && isFuture(date))
  //   return `Starts on ${formatDate('day', date)}`
  if (isThisMonth(date))
    return `Starts on ${formatDate('condensed-readable', date)}`
  if (isSameMonth(addMonths(new Date(), 1), date)) return 'Starts Next Month'
  return `Starts in ${formatDate('month', date)}`
}

export const expandEventProductDates = <T extends EventProduct>(product: T) => {
  if (!product.recurrence) return [product]
  const dates = getRRuleDates(product.recurrence)
  return dates.map((date) => ({
    ...product,
    startTime: date,
    endTime: addMinutes(
      date,
      differenceInMinutes(product.endTime, product.startTime),
    ),
  }))
}
