import {
  DatabaseModel,
  DatabaseRelations,
  GetWhere,
  Models,
} from '@signupsoftware/server'
import axios from 'axios'
import { onlyDefinedKeys } from '@unimpaired/utils'
import { apiClient } from '../../config/axios.config'
import { capitalCase } from 'change-case'
const headers = { 'Content-Type': 'application/json; charset=utf-8' }

type GetDatabaseValues<Value extends object> = {
  [K in keyof Value as Value[K] extends
    | number
    | string
    | Date
    | boolean
    | object
    ? K
    : never]: Value[K]
}

type OmitDefaults<T extends object> = Omit<
  T,
  'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'
>

// type CreateRelations<T extends object> = {
//   [K in keyof T]?: T[K] extends any[] ? { connect: { id: number }[] } : number
// }
type CreateRelations<T extends object> = {
  [K in keyof T]?: T[K] extends any[] ? { connect: { id: number }[] } : number
}

type UpdateRelations<T extends object> = {
  [K in keyof T]: T[K] extends any[]
    ? { set?: { id: number }[]; connect?: { id: number }[] }
    : number
}

export const getApi = <T extends keyof DatabaseModel>(model: T) => {
  const endpoint = capitalize(model)
  type Single = DatabaseModel[T]

  type Relations = DatabaseRelations[T]

  type Create = Partial<OmitDefaults<Single>> &
    OmitDefaults<GetDatabaseValues<Single>> &
    Partial<CreateRelations<Relations>>

  type Update = Partial<OmitDefaults<Single>> &
    Partial<UpdateRelations<Relations>>

  const getList = async <R extends (keyof Relations)[] = []>(
    where: GetWhere<T, Pick<Relations, R[number]>> = {},
    options: {
      include?: R
      select?: R
      sortProp?: keyof Single extends string ? keyof Single : string
      sortDirection?: 'asc' | 'desc'
      cursor?: string | Date
      take?: number
    } = {},
  ) => {
    type Response = {
      data: ({ createdAt: Date; updatedAt: Date; id: number } & Single &
        NonNullable<Pick<Relations, R[number]>>)[]
      count: number
    }

    const body = onlyDefinedKeys({
      where: onlyDefinedKeys(where),
    })

    const params = onlyDefinedKeys({
      include: options?.include?.join(',').trim(),
      select: options?.select?.join(',').trim(),
      sortProp: options?.sortProp,
      sortDirection: options?.sortDirection,
      take: options?.take,
    })

    return apiClient
      .post<Response>(`/query/${endpoint}`, body, {
        headers,
        params,
      })
      .then((res) => res.data)
  }

  const getSingle = async <R extends (keyof Relations)[] = []>(
    id: number | string,
    options: { include?: R; select?: R } = {},
  ) => {
    type Response = Single & NonNullable<Pick<Relations, R[number]>>
    const params = onlyDefinedKeys({
      include: options?.include?.join(','),
      select: options?.select?.join(','),
    })

    const { data } = await apiClient.get<Response>(`/${endpoint}/${id}`, {
      headers,
      params,
    })
    return data
  }

  const createOne = async <R extends (keyof Relations)[] = []>(
    body: Create,
    options: { include?: R; select?: R } = {},
  ) => {
    type Response = Single & NonNullable<Pick<Relations, R[number]>>

    const params = onlyDefinedKeys({
      include: options?.include?.join(','),
      select: options?.select?.join(','),
    })

    try {
      const { data } = await apiClient.post<Response>(`/${endpoint}`, body, {
        headers,
        params,
      })
      return data
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }

  const updateOne = async <R extends (keyof Relations)[] = []>(
    id: number,
    body: Update,
    includes?: R,
  ) => {
    type Response = Single & NonNullable<Pick<Relations, R[number]>>

    try {
      const { data } = await apiClient.patch<Response>(
        `${endpoint}/${id}`,
        body,
        {
          headers,
          params: { include: includes?.join(',') },
        },
      )
      return data
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }

  const deleteOne = async (id: number) => {
    const { data } = await apiClient.delete<Single>(`${endpoint}/${id}`, {
      headers,
      params: { id },
    })
    return data
  }

  return { getList, createOne, updateOne, deleteOne, getSingle }
}

function capitalize(string: string) {
  if (!string) return string // Check if the string is empty or null
  return string.charAt(0).toUpperCase() + string.slice(1)
}
