import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faArrowRight,
  faBackspace,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animated, useTransition } from '@react-spring/web'
import { useIsMobile, useRouter } from '@unimpaired/react'
import { capitalCase } from 'change-case'
import { equals, indexOf, tail } from 'ramda'
import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { UiContext } from '../UiContext'
import useMeasure from 'react-use-measure'

type BreadCrumb = {
  text: string
  route: string
  icon?: IconProp
}

export type RouteNameOverrides = Record<string, Omit<BreadCrumb, 'route'>>

export const GLOBAL_BREADCRUMB_HEIGHT = 35

export const translateRouteToCrumbs = (
  url: string,
  overrides: Record<string, string>,
) => {
  return tail(url)
    .split('/')
    .reduce((crumbList, currentRoute, i) => {
      const route = `${crumbList[i - 1]?.route || ''}/${currentRoute}`

      const text = overrides[currentRoute]
        ? overrides[currentRoute]
        : capitalCase(currentRoute)

      return [...crumbList, { route, text }]
    }, [] as BreadCrumb[])
}

type Props = {
  // By default, the current route is switch to an array of strings:
  // ex:  'learn/group-classes' -> ['Learn', 'Group Classes']
  // This property overrides this behavior.
  // ex: route: 'learn/group-classes'
  //     nameOverrides: { [`group-classes`]: 'Classes', [`learn`]: 'Learn To Dance' }
  //     newTitle:  ['Learn To Dance', 'Classes']
  onClickTitle?: (title: string) => void
}

export const ApplicationBreadcrumbs = (props: Props) => {
  const isMobile = useIsMobile()
  const { breadcrumbOverrides } = useContext(UiContext)
  const [crumbs, setCrumbs] = useState<BreadCrumb[]>([])
  const [crumbsToDisplay, setCrumbsToDisplay] = useState(3)
  const { currentRoute, navigateTo } = useRouter()
  const [ref, { width }] = useMeasure()

  useEffect(() => {
    if (isMobile) {
      setCrumbs(() => {
        const newCrumbs = translateRouteToCrumbs(
          currentRoute,
          breadcrumbOverrides,
        )

        const length = newCrumbs.flatMap((c) => c.text).join('').length
        const slice = length > 25 ? 2 : 3
        setCrumbsToDisplay(slice)
        return newCrumbs.slice(-slice)
      })
    } else {
      setCrumbs(() => {
        const newCrumbs = translateRouteToCrumbs(
          currentRoute,
          breadcrumbOverrides,
        )

        return newCrumbs
      })
    }
  }, [currentRoute, breadcrumbOverrides])

  const animation = useTransition(crumbs, {
    from: {
      opacity: 0,
      transform: 'translateX(-20%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
  })

  // if (currentRoute.split('/').length === 2) return null
  // if (currentRoute === '/') return null
  return (
    <nav
      className='bg-gray-800 border-t flex items-center justify-start'
      ref={ref}
      style={{ height: GLOBAL_BREADCRUMB_HEIGHT }}>
      <button
        className='text-white px-2'
        onClick={() => (crumbs.length > 1 ? navigateTo(-1) : navigateTo('/'))}>
        <FontAwesomeIcon
          icon={crumbs.length > 1 ? faArrowLeft : faHome}
          className='w-4 h-4 px-2'
        />
      </button>

      {/* <ol className='flex items-center'> */}
      {isMobile && currentRoute.split('/').length > crumbsToDisplay + 1 && (
        <>
          <svg
            className='h-8 min-h-full w-3 flex-shrink-0 text-gray-400'
            viewBox='0 0 24 44'
            preserveAspectRatio='none'
            fill='currentColor'
            aria-hidden='true'>
            <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
          </svg>
          <li className='px-2 flex items-center'>
            <button
              onClick={() => {
                // TODO:  replace this with opening up a drawer?
                const splitRoute = currentRoute.split('/')
                const prevRoute = currentRoute
                  .split('/')
                  .slice(-splitRoute.length, crumbsToDisplay + 1)
                  .join('/')
                navigateTo(prevRoute)
              }}
              className='flex text-gray-400 text-xl items-center truncate'>
              ...
            </button>
          </li>
        </>
      )}

      <ol
        role='list'
        className='sm:max-w-4xl flex space-x-3 items-center justify-center'>
        {animation((style, crumb) => (
          <>
            <svg
              className='h-8 min-h-full w-3 flex-shrink-0 text-gray-400'
              viewBox='0 0 24 44'
              preserveAspectRatio='none'
              fill='currentColor'
              aria-hidden='true'>
              <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
            </svg>

            <animated.li style={style} key={crumb.route} className='flex'>
              <div className='flex items-center space-x-1'>
                {/* {indexOf(crumb, crumbs) !== 0 && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className='w-5 h-5 text-black mx-2'
                />
              )} */}

                {/* <button className={`text-sm font-medium sm:w-full text-black`}>
                {crumb.text}
              </button> */}

                <NavLink
                  to={crumb.route}
                  className={({ isActive, isPending }) =>
                    `text-xs pl m-0 font-medium transition max-w-fit truncate ${
                      isActive ? 'text-gray-200' : 'text-gray-200'
                    } hover:text-blue-200`
                  }>
                  {crumb.text}
                </NavLink>
              </div>
            </animated.li>
          </>
        ))}
      </ol>

      {/* <nav className='flex' aria-label='Breadcrumb'>
        <ol
          role='list'
          className='bg-white rounded-md shadow px-6 flex space-x-4'>
          {crumbs.map((crumb) => (
            <li key={crumb.text} className='flex'>
              <div className='flex items-center'>
                {indexOf(crumb, crumbs) !== 0 && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className='w-6 h-6 text-gray-200 mx-2'
                  />
                )}

                <Link
                  to={crumb.route}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
                  {crumb.text}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav> */}
    </nav>
  )
}
