import {
  DatabaseModel,
  DatabaseRelations,
  GetWhere,
} from '@signupsoftware/server'
import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { getApi } from '../helpers/getApi'

export const useDatabaseListPage = <
  T extends keyof DatabaseModel,
  Relations extends Record<keyof Required<DatabaseRelations[T]>, any>,
>(
  model: T,
  where: GetWhere<T, Relations>,
) => {
  const take = 10

  const [searchTerm, setSearchTerm] = useState<string>()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [sortProp, setSortProp] = useState('id')

  const { data: response, ...query } = useInfiniteQuery({
    queryKey: [
      `${model}-admin-list`,
      searchTerm,
      sortDirection,
      sortProp,
      where,
    ],
    queryFn: ({ pageParam }) =>
      getApi(model).getList(
        {
          ...where,
          name: { contains: searchTerm },
        },
        {
          cursor: pageParam,
          take,
          // @ts-ignore
          // sortDirection: sortDirection,
          // sortProp: sortProp,
        },
      ),
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage) return undefined
      const mostRecentRecord = lastPage.data[take]

      if (!mostRecentRecord) return

      const newCursor = mostRecentRecord[sortProp]
      return newCursor
    },
  })

  return {
    data: response?.pages.flatMap((page) => page.data),
    count: response?.pages.flatMap((page) => page.count)[
      response.pages.length - 1
    ],
    searchTermState: [searchTerm, setSearchTerm] as [string, SetState<string>],
    sortDirectionState: [sortDirection, setSortDirection] as [
      'asc' | 'desc',
      SetState<'asc' | 'desc'>,
    ],
    sortPropState: [sortProp, setSortProp] as [
      'createdAt' | 'updatedAt' | 'id',
      SetState<'createdAt' | 'updatedAt' | 'id'>,
    ],
    error: query.error,
    isError: query.isError,
    isErrorOnFirstRequest: query.isLoadingError,
    isErrorOnRefetch: query.isRefetchError,
    isLoadingFirstRequest: query.isLoading,
    isLoadingNextPage: query.isFetchingNextPage,
    isWaitingForDependency: query.isIdle,
    isFetching: query.isFetching,
    isRefetching: query.isRefetching,
    fetchNextPage: query.fetchNextPage,
    refetch: query.refetch,
  }
}

const useUrlQueryData = () => {
  const [urlParams, setUrlParams] = useSearchParams()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>()
  const [sortProp, setSortProp] = useState<string>()

  useEffect(() => {
    if (!searchTerm) return
    setUrlParams({ searchTerm })
  }, [searchTerm])

  useEffect(() => {
    if (!sortDirection) return
    setUrlParams({ sortDirection })
  }, [sortDirection])

  useEffect(() => {
    if (!sortProp) return
    setUrlParams({ sortProp })
  }, [sortProp])

  return {
    searchTermState: [searchTerm, setSearchTerm] as [string, SetState<string>],
    sortDirectionState: [sortDirection, setSortDirection] as [
      'asc' | 'desc',
      SetState<'asc' | 'desc'>,
    ],
    sortPropState: [sortProp, setSortProp] as [
      'createdAt' | 'updatedAt' | 'id',
      SetState<'createdAt' | 'updatedAt' | 'id'>,
    ],
  }
}
