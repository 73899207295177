import { Link, useNavigate, useRouteError } from 'react-router-dom'

type Props = {
  goBackPath?: string
  goBackText?: string
}

export const NotFoundPage = ({ goBackPath, goBackText }: Props) => {
  const error = useRouteError() as
    | { message: string }
    | { response: { data: any } }
  const navigateTo = useNavigate()
  const getMessage = () => {
    if (!error) return 'Page not found'
    if ('response' in error) {
      return error.response.data.message
    }
    if ('message' in error) return error.message
    return 'Page not found'
  }

  return (
    <div className='grid place-items-start px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-indigo-600'>404</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
          {getMessage()}
        </h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            to={goBackPath || '/'}
            className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            Go Home
          </Link>
          <button
            onClick={() => navigateTo(-1)}
            className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
            Go Back
          </button>
        </div>
      </div>
    </div>
  )
}
