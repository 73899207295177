import { useContext, useLayoutEffect } from 'react'
import { UiContext } from '../UiContext'

export const useBreadcrumbOverride = (override: Record<string, string>) => {
  const { setBreadcrumbOverrides, breadcrumbOverrides } = useContext(UiContext)

  useLayoutEffect(() => {
    Object.entries(override).forEach(([prevString, newString]) => {
      if (breadcrumbOverrides[prevString]) return
      setBreadcrumbOverrides((prev) => ({ ...prev, [prevString]: newString }))
    })
  }, [override])
}
