import {
  faBars,
  faCalendar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faClose,
  faDollarSign,
  faEdit,
  faGear,
  faPeopleGroup,
  faPerson,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AdminTable,
  BodyContainer,
  BodyContent,
  Button,
  ConfirmationButton,
  DataCard,
  EventDateRangeField,
  FadeIn,
  FullPageHeader,
  FullPageSidebar,
  Header,
  MonthSelect,
  PageContent,
  Search,
  SectionContainer,
  SectionContent,
  SidebarSection,
  useIsMobile,
} from '@unimpaired/react'
import { ArrayInfer, formatDate, startOfNextMonth } from '@unimpaired/utils'
import { prop } from 'ramda'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAdminContext } from '../shared/hooks/useAdminContext'
import { useDatabaseListPage } from '../shared/hooks/useDashboardListPage'
import { Layout } from '../shared/components/Layout'
import { toast } from 'react-toastify'
import { addMonths, startOfMonth } from 'date-fns'
import { EventPublishModal } from '../shared/components/EventPublishModal'
import { canPublishClassIssues } from '../shared/helpers/eventProduct.helpers'
import { getApi } from '../shared/helpers/getApi'

export const GroupClassDashboardPage = () => {
  const { organization } = useAdminContext()
  const [startDate, setStartDate] = useState(startOfMonth(new Date()))
  const [endDate, setEndDate] = useState(
    addMonths(startOfNextMonth(new Date()), 2),
  )

  const {
    data,
    count,
    isFetching,
    refetch,
    searchTermState: [searchTerm, setSearchTerm],
  } = useDatabaseListPage('groupClass', {
    organizationId: { equals: organization.id },
  })

  const [selectedGroupClass, setSelectedGroupClass] =
    useState<ArrayInfer<typeof data>>()

  const isMobile = useIsMobile()
  const navigateTo = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState(true)

  useLayoutEffect(() => {
    setSidebarOpen(!isMobile)
  }, [])

  return (
    <FadeIn className='bg-gray-900'>
      <div className='flex flex-col h-full bg-gray-50'>
        <Header title='Group Class Dashboard' className='p-4 w-full'>
          <Link
            to='create'
            className='rounded bg-indigo-500 px-4 py-2 min-w-fit flex items-center justify-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
            <FontAwesomeIcon icon={faPlus} className='h-4 w-4 pr-2' />
            <span className=''>Create</span>
          </Link>
        </Header>

        {/* <FullPageSidebar hidden={sidebarOpen}>
          <SidebarSection title='From'>
            <MonthSelect date={startDate} setDate={setStartDate}></MonthSelect>
          </SidebarSection>
          <SidebarSection title='To'>
            <MonthSelect date={endDate} setDate={setEndDate}></MonthSelect>
          </SidebarSection>
          <SidebarSection title='Data'>
            <dl className='mt-5 grid grid-cols-1 gap-5 p-4'>
              <DataCard
                title='All Time Revenue'
                icon={faDollarSign}
                onClickMore={() => {
                  toast.info('Coming Soon')
                }}
              />

              <DataCard
                title='Attendees this Month'
                icon={faCalendar}
                onClickMore={() => {
                  toast.info('Coming Soon')
                }}
              />

              <DataCard
                title='Revenue this Month'
                icon={faPerson}
                onClickMore={() => {
                  toast.info('Coming Soon')
                }}
              />
            </dl>
          </SidebarSection>
        </FullPageSidebar> */}

        <Header className='sm:px-4'>
          {/* <button
              className='h-full flex items-center p-4'
              onClick={() => setSidebarOpen((v) => !v)}>
              <FontAwesomeIcon icon={sidebarOpen ? faBars : faClose} />
            </button> */}

          <Search
            className='h-16'
            placeholder='search classes'
            onSearch={setSearchTerm}
            defaultValue={searchTerm}
            debounce
          />
        </Header>

        <EventPublishModal
          refetch={refetch}
          issues={
            selectedGroupClass ? canPublishClassIssues(selectedGroupClass) : []
          }
          isOpen={!!selectedGroupClass}
          onClose={() => setSelectedGroupClass(undefined)}
        />

        <div className='shadow-xl p-2 border h-full w-full overflow-y-auto'>
          <AdminTable
            onCreate={() => navigateTo(`create`)}
            hideLoader={data?.length === count || !isFetching}
            fetchingNextPage={isFetching}
            rowsPerPage={10}
            tableSchema={{
              published: (groupClass) => (
                <div className='flex items-center justify-start'>
                  {groupClass.published ? (
                    <FontAwesomeIcon
                      className='text-green-400 pl-6 h-5 w-5'
                      icon={faCheckCircle}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        setSelectedGroupClass(groupClass)
                      }}>
                      Publish
                    </Button>
                  )}
                </div>
              ),
              name: ({ name, id }) => (
                <Link
                  className='truncate w-32 font-medium text-blue-400 hover:text-indigo-800 transition'
                  to={`${id}`}>
                  <FontAwesomeIcon icon={faGear} className='pr-2' />
                  {name}
                </Link>
              ),
              // @ts-ignore
              'View Attendees': ({ id }) => (
                <Link
                  to={`${id}/attendees`}
                  className='font-medium text-blue-400'>
                  <FontAwesomeIcon icon={faPeopleGroup} className='pr-2' />
                  attendees
                </Link>
              ),
              'View Revenue': ({ id }) => (
                <Link
                  to={`${id}/analytics`}
                  className='font-medium text-blue-400'>
                  <FontAwesomeIcon icon={faChartPie} className='pr-2' />
                  analytics
                </Link>
              ),
              Month: ({ startTime }) =>
                formatDate('month', startTime) +
                ' ' +
                new Date(startTime).getFullYear(),
              'Start Date': ({ startTime }) =>
                `${formatDate('condensed-readable', startTime)}, ${new Date(
                  startTime,
                ).getFullYear()}`,
              Delete: (groupClass) => (
                <ConfirmationButton
                  onClick={() => {
                    getApi('groupClass').deleteOne(groupClass.id)
                  }}>
                  Delete
                </ConfirmationButton>
              ),
            }}
            recordsList={data}></AdminTable>
        </div>
      </div>
    </FadeIn>
  )
}

export default GroupClassDashboardPage
