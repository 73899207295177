import { Organization } from '@signupsoftware/server'
import {
  Button,
  H1,
  Page,
  RoutingNav,
  Section,
  useIsMobile,
  useRouter,
} from '@unimpaired/react'
import { Outlet, useOutletContext } from 'react-router'
import { useBreadcrumbOverride } from '../shared/hooks/useBreadcrumbOverride'
import {
  faArrowLeft,
  faClipboard,
  faObjectGroup,
  faPeopleGroup,
  faRunning,
} from '@fortawesome/free-solid-svg-icons'

type Props = {
  admin?: boolean
  links: {
    route: string
    text: string
    description: string
    disabled?: boolean
  }[]
}

export const OrgNavPage = (props: Props) => {
  const { organization } = useOutletContext() as { organization: Organization }
  const isMobile = useIsMobile()
  const { navigateTo } = useRouter()

  useBreadcrumbOverride({
    [organization.id]: organization.name,
  })

  return (
    <Page
      title={organization.name}
      titleLink={isMobile && props.admin ? 'scan' : undefined}
      titleLinkText={props.admin ? 'Scan Tickets' : ''}
      // titleActionsComponent={
      //   <Button
      //     type='button'
      //     variant='outline-dark'
      //     iconPosition='left'
      //     className='min-w-fit'
      //     icon={faArrowLeft}
      //     onClick={() => navigateTo('..')}>
      //     <p>Back</p>
      //   </Button>
      // }
      pageDescription={
        props.admin
          ? 'Choose which part of your organization you would like to manage'
          : ''
      }>
      <RoutingNav variant='dark' navItems={props.links} />
    </Page>
  )
}

export const storeLinks = [
  {
    route: `classes`,
    text: 'Group Classes',
    // description: 'Check out our group classes. Learn to dance with us!',
    // NOTE:  This is a hack fix in order to make the Jazz Attack people happy
    //        They wanted this description to change, but I don't have time
    //        to create a U.I. management system for this.  In the future, we
    //        should have a spot in the admin dashboard for them to modify this.
    description: 'Classes come with admission to that evening’s social dance',
    icon: faPeopleGroup,
  },
  {
    route: `dances`,
    text: 'Social Dances',
    description: 'Check out social dances. Dance with us!',
    icon: faRunning,
  },
]

export const adminLinks = [
  {
    route: `overview`,
    text: 'Dashboard Overview',
    description: 'Explore business performance and finances',
  },
  {
    route: `offerings`,
    text: 'Classes, Events, & Offerings',
    description: 'Classes, Events, Weekend Workshops, Physical Products, etc.',
  },
  {
    route: `profile`,
    text: 'Business Profile',
    description: 'Manage your business settings',
  },
  {
    route: 'staffing',
    text: `Staff`,
    description:
      'Add staff, view and manage schedules, send reminders, pay employees',
    comingSoon: true,
  },
  {
    route: 'marketing',
    text: `Emails & Marketing`,
    description: 'Send emails, manage marketing campaigns, run advertisements',
    comingSoon: true,
  },
  {
    route: 'metrics',
    text: `Analytics`,
    description: 'View pricing information',
    comingSoon: true,
  },
  // {
  //   route: 'contact',
  //   text: `Contact Us`,
  //   description: 'Request features, report bugs, ask questions',
  // },
]
