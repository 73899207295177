const DEV_ENVIRONMENT = {
  STRIPE_PUBLIC_KEY:
    'pk_test_51Iy6HWKUoUu4ywBBLZYy4Uvg0pXP2hxVVug2k2hAumy4FjdnEUb87MKvJ1vA55XaL8k4ohvk1rLBix5JGiRDhpGC00CYRXjiLt',
  WEBSITE_URL: 'https://localhost:5173',
  SERVER_URL: 'http://localhost:4000',
  SENTRY_DSN:
    'https://3152e800edca25f615dacf6f773cca2c@o4506766680915968.ingest.us.sentry.io/4506766682161152',
  GOOGLE_MAPS: 'AIzaSyDoH7uHmTOFZgT72FQSufRHj2JcLladvrc',
}

const PROD_ENVIRONMENT = {
  STRIPE_PUBLIC_KEY:
    'pk_live_51Iy6HWKUoUu4ywBBEfTOP6tw5jdXQZ0ly3VgcezqxeJea2wzZLKiqUhF2RaQzG5qat2wpUcsx9a09ev4SCJ6Ql23002FgRsCGA',
  WEBSITE_URL: 'https://signup.software',
  SERVER_URL: 'https://api.signup.software',
  SENTRY_DSN:
    'https://3152e800edca25f615dacf6f773cca2c@o4506766680915968.ingest.us.sentry.io/4506766682161152',
  GOOGLE_MAPS: 'AIzaSyDoH7uHmTOFZgT72FQSufRHj2JcLladvrc',
}

export const ENVIRONMENT = import.meta.env.DEV
  ? DEV_ENVIRONMENT
  : PROD_ENVIRONMENT
